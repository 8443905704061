









import { Component, Vue } from "vue-property-decorator";
import VersionBackModule from "@/store/modules/VersionBack-module";
import { UtilsString } from "@/utils/utils-string";
import { LocalStorageService } from "@/shared/services/LocalStorageConfig-service";
import usuarioModule from "@/store/modules/usuario-module";
@Component({
  components: {
    GaugesChart: () => import("@/components/Charts/GaugesChart.vue"),
    GaugesChartNormal: () =>
      import("@/components/Charts/GaugesChartNormal.vue"),
    DashboardAdmin: () => import("@/views/home/DashboardAdmin.vue"),
    DashboardAyuntamiento: () =>
      import("@/views/home/DashboardAyuntamiento.vue"),
  },
})
export default class Home extends Vue {
  public dialogNewVersion: boolean = false;
  public height = (25 * this.$vuetify.breakpoint.height) / 100;
  public async created() {
    //VersionBackModule.getVersionFront().then(() => this.isnewversion());
    await usuarioModule.getmiusuario();
  }

  public mounted() {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
      appLoading.style.display = "none";
    }
  }

  public get EsAyuntamiento() {
    return (
      usuarioModule.mi_usuario.id_ayuntamiento === undefined ||
      usuarioModule.mi_usuario.id_ayuntamiento > 0
    );
  }

  public get ancho() {
    let menos = this.$vuetify.breakpoint.xsOnly ? 10 : 280;
    return this.$vuetify.breakpoint.width - menos;
  }

  public get alto() {
    let alt = 1750;
    if (this.$vuetify.breakpoint.xl) {
      return 790;
    }
    return alt;
  }

  public isnewversion() {
    //Estoy en una versión mas antigua?
    VersionBackModule.GetVersionEnv();
    let vlocal = LocalStorageService.getVersion()
      .trim()
      .replace(".", "")
      .replace(".", "");
    let vEnv = VersionBackModule.versionfrontEnv
      .trim()
      .replace(".", "")
      .replace(".", "");
    let vfront = VersionBackModule.versionfront
      .trim()
      .replace(".", "")
      .replace(".", "");
    //si la version de front que devulve el back es mas nueva que la que tiene en cache/entorno
    if (Number.parseInt(vfront) > Number.parseInt(vEnv)) {
      //Pulsamos F5 para refrescar la pagina
      document.location.reload(true);
    }

    if (UtilsString.IsNullOrWhiteSpace(vlocal)) {
      this.dialogNewVersion = false;
      LocalStorageService.setVersion(VersionBackModule.versionfrontEnv);
      return false;
    }
    //si la version que tiene en el fichero de entorno es mas nueva que la ultima que ha ejecutado
    if (Number.parseInt(vEnv) > Number.parseInt(vlocal)) {
      this.dialogNewVersion = true;
      LocalStorageService.setVersion(VersionBackModule.versionfrontEnv);
    } else {
      this.dialogNewVersion = false;
      LocalStorageService.setVersion(VersionBackModule.versionfrontEnv);
      return false;
    }
    return true;
  }

  public goto(name: string) {
    this.$router.push({ name: name });
  }
}
